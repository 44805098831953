import { ref } from "@vue/reactivity";
import { projectAuth, projectFirestore } from "../firebase/config";
const isLoading = ref(false);
const error = ref("");

// Import Firebase GoogleAuthProvider from compat
import firebase from "firebase/compat/app";

const login = async (email, password) => {
	error.value = null;
	isLoading.value = true;

	// Sign the user in
	try {
		await projectAuth.signInWithEmailAndPassword(email, password);
	} catch (err) {
		console.log(err.code);

		switch (err.code) {
			case "auth/invalid-credential":
				error.value =
					"Invalid credential. Please check your email and password.";
				break;
			case "auth/too-many-requests":
				error.value = "Too many login attempts. Try again later.";
				break;
			default:
				error.value =
					"Something went wrong, please try again later or contact us.";
		}
		isLoading.value = false;
	}
};

const googleLogin = async () => {
	error.value = null;
	isLoading.value = true;

	const provider = new firebase.auth.GoogleAuthProvider();

	try {
		await projectAuth.signInWithPopup(provider);
	} catch (err) {
		console.log(err.message);
		error.value = "Failed to sign in with Google";
		isLoading.value = false;
	}
};

const useLogin = () => {
	return { error, isLoading, login, googleLogin };
};

export default useLogin;
