import { watchEffect, ref } from "@vue/runtime-core";
// import { inject } from "vue";
import EnvUtils from "../misc/env";
import { useSessionStore } from "../stores/sessionStore";
import { storeToRefs } from "pinia";
import useCookies from "../composables/useCookies";

const useBeacon = (cryptojs) => {
    const error = ref(null);
    const loaded = ref(false);
    const signature = ref(null);
    const sessionStore = useSessionStore();
    const { getCookie } = useCookies();
    const companyId = getCookie("rm_cId");

    const { user, employeeDoc, companyDoc } = storeToRefs(sessionStore);

    // const cryptojs = inject("cryptojs");

    const generateSignature = () => {
        if (loaded.value) {
            var hash = cryptojs.HmacSHA256(
                user.value.email,
                EnvUtils.HELP_SCOUT
            );
            signature.value = cryptojs.enc.Hex.stringify(hash);
        }
    };

    watchEffect(() => {
        if (cryptojs && user.value && employeeDoc.value && companyDoc.value) {
            loaded.value = true;
            generateSignature();
        }
    });

    const identify = () => {
        if (
            loaded.value &&
            signature.value &&
            employeeDoc.value &&
            companyDoc.value
        ) {
            window.Beacon("identify", {
                name: user.value.displayName,
                email: user.value.email,
                companyId: companyId,
                company: companyDoc.value.companyName,
                subscriptionStatus: companyDoc.value.subscription.status,
                jobTitle: employeeDoc.value.profile.jobTitle,
                role: employeeDoc.value.role,
                userId: user.value.uid,
                signature: signature.value,
            });
        }
    };

    const beaconLogout = () => {
        try {
            if (typeof window.Beacon === "function") {
                window.Beacon("logout", { endActiveChat: true });
            }
        } catch (e) {
            console.log("Beacon logout error: ", e);
        }
    };

    const welcomeMessage = () => {
        window.Beacon("show-message", "95f4f6f1-5131-4ff7-aa30-98082fc51af4");
    };

    return { identify, beaconLogout, welcomeMessage };
};

export default useBeacon;
