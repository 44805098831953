import { defineStore } from "pinia";
import { projectFirestore, timestampFromDate } from "../firebase/config";
import { ref } from "vue";
import useCookies from "../composables/useCookies";
import { useSessionStore } from "../stores/sessionStore";
import { storeToRefs } from "pinia";

export const useChecklistsStore = defineStore("checklists", () => {
    const { getCookie } = useCookies();

    const sessionStore = useSessionStore();
    const { user, employeeDoc } = storeToRefs(sessionStore);

    let companyId = getCookie("rm_cId");

    // Initialize the cache
    let cachedRanges = {
        scheduled: { from: null, to: null },
        active: { from: null, to: null },
        complete: { from: null, to: null },
        archived: { from: null, to: null },
    };

    // Define separate arrays for each status and isArchived
    const scheduledChecklists = ref([]);
    const activeChecklists = ref([]);
    const completedChecklists = ref([]);
    const archivedChecklists = ref([]);

    const fetchingData = ref(false);
    const fetchChecklistError = ref(null);

    const selectedOption = ref(null);

    const resetChecklistsStore = () => {
        cachedRanges = {
            scheduled: { from: null, to: null },
            active: { from: null, to: null },
            complete: { from: null, to: null },
            archived: { from: null, to: null },
        };
        companyId = null;
        user.value = null;
        employeeDoc.value = null;
        scheduledChecklists.value = [];
        activeChecklists.value = [];
        completedChecklists.value = [];
        archivedChecklists.value = [];
        fetchingData.value = false;
        fetchChecklistError.value = null;
        selectedOption.value = null;
    };

    const refreshChecklists = () => {
        cachedRanges = {
            scheduled: { from: null, to: null },
            active: { from: null, to: null },
            complete: { from: null, to: null },
            archived: { from: null, to: null },
        };
        scheduledChecklists.value = [];
        activeChecklists.value = [];
        completedChecklists.value = [];
        archivedChecklists.value = [];
        fetchingData.value = false;
        fetchChecklistError.value = null;
    };

    const userHasAccess = (checklist) => {
        const conditions = [
            // Is Admin
            () => employeeDoc.value.role == "Admin",
            // Manages the location
            () => employeeDoc.value.manages?.includes(checklist.location.id),
            // Is the owner
            () => checklist.owner === employeeDoc.value.id,
            // Is a collaborator
            () => checklist.checklistCollaborators?.some(
                (element) =>
                    typeof element === "object" &&
                    element.hasOwnProperty("id") &&
                    element["id"] === employeeDoc.value.id
            ),
            // Checklist is completed and is shared completed with
            () => checklist.sharedCompletedChecklistWithPeople?.some(
                (element) =>
                    typeof element === "object" &&
                    element.hasOwnProperty("id") &&
                    element["id"] === employeeDoc.value.id
            ) && checklist.status === "complete",
            // Manually shared via email & checklist complete
            () => checklist.sharedEmails?.includes(employeeDoc.value.email) && checklist.status === "complete",
            // User added via question & checklist complete
            () => checklist.users?.includes(employeeDoc.value.id) && checklist.status === "complete"
        ];

        return conditions.some(condition => condition());
    };

    const sortChecklists = (checklists, status) => {
        checklists.sort((b, a) => {
            try {
                if (status === "scheduled") {
                    return b.scheduledDate.toMillis() - a.scheduledDate.toMillis();
                } else if (status === "active" || status === "archived") {
                    return a.createdAt.toMillis() - b.createdAt.toMillis();
                } else if (status === "complete") {
                    return a.completedAt.toMillis() - b.completedAt.toMillis();
                } else {
                    console.error('Missing date property on checklist:', b, a);
                    return 0;
                }
            } catch (error) {
                console.error('Error with checklist:', b, a, error);
                return 0;
            }
        });
        return checklists;
    };

    const fetchChecklists = async (from, to, status, isArchived) => {
        fetchingData.value = true;
        fetchChecklistError.value = null;

        if (!companyId) {
            companyId = getCookie("rm_cId");
        }
        try {

            if (!companyId, !user.value, !employeeDoc.value) {
                return;
            }

            if (from && to) {
                from.setHours(0, 0, 0, 0);
                let startOfFrom = timestampFromDate(from);

                to.setHours(23, 59, 59, 999);
                let endOfTo = timestampFromDate(to);

                from = startOfFrom;
                to = endOfTo;
            }

            let dateField = 'createdAt';

            if (status === 'scheduled') {
                dateField = 'scheduledDate';
            } else if (status === 'active') {
                dateField = 'createdAt'
            } else if (status === 'complete') {
                dateField = 'completedAt'
            }

            // Get the cached range for the status
            let cachedRange = cachedRanges[status || 'archived'];

            let dateRangeQueries = [];

            if (cachedRange.from === null || cachedRange.to === null) {

                const dateRangeQuery = {
                    from: {
                        field: dateField,
                        operator: ">=",
                        value: from,
                    },
                    to: {
                        field: dateField,
                        operator: "<=",
                        value: to,
                    },
                };
                dateRangeQueries.push(dateRangeQuery);


                // Update the cache
                cachedRange.from = from;
                cachedRange.to = to;
            } else {
                // If the from date is earlier than the cached from date
                if (from < cachedRange.from) {
                    const dateRange = {
                        from: {
                            field: dateField,
                            operator: ">=",
                            value: from,
                        },
                        to: {
                            field: dateField,
                            operator: "<=",
                            value: cachedRange.from,
                        },
                    };

                    // Add the range to the list
                    dateRangeQueries.push(dateRange);

                    // Update the from var to the new earlier value
                    cachedRange.from = from;
                }

                // If the to date is greater than the cached to date
                if (to > cachedRange.to) {
                    const dateRange = {
                        from: {
                            field: dateField,
                            operator: ">=",
                            value: cachedRange.to,
                        },
                        to: {
                            field: dateField,
                            operator: "<=",
                            value: to,
                        },
                    };

                    // Add the range to the list
                    dateRangeQueries.push(dateRange);

                    // Update the cached to date with the new to date
                    cachedRange.to = to;
                }
            }

            let promises = [];

            if (dateRangeQueries.length > 0) {
                // Get documents from each new date range
                for (const range of dateRangeQueries) {
                    // Loop through the queries that can't be called together
                    let buckets = [
                        {
                            field: "companyId",
                            operator: "==",
                            value: companyId,
                        },
                        {
                            field: "thirdPartyCompanyId",
                            operator: "==",
                            value: companyId,
                        },
                    ];

                    if (user.value && user.value.email && user.value.email.trim() !== "") {
                        buckets.push({
                            field: "sharedEmails",
                            operator: "array-contains",
                            value: user.value.email,
                        });
                    }

                    // Get the data for the 3 "bucket" queries
                    for (const bucket of buckets) {
                        if (
                            typeof bucket.value !== "string" ||
                            bucket.value === "" || bucket.value === null
                        ) {
                            continue;
                        }

                        // Build the query
                        const db = projectFirestore.collection("Checklist");

                        // By companyId, thirdPartyId or sharedEmails arrayContains
                        let query = db.where(
                            bucket.field,
                            bucket.operator,
                            bucket.value
                        );

                        // Add the uncached from a to query
                        let rangeQuery = query
                            .where(
                                range.from.field,
                                range.from.operator,
                                range.from.value
                            )
                            .where(
                                range.to.field,
                                range.to.operator,
                                range.to.value
                            )

                        if (status) {
                            rangeQuery = rangeQuery.where("status", "==", status);
                        }

                        rangeQuery = rangeQuery
                            .where("isArchived", "==", isArchived)

                        // Execute the query and save the last document
                        let queryPromise = rangeQuery.get();
                        promises.push(queryPromise);
                    }
                }
            }

            // Wait for all the promises to resolve
            let results = await Promise.all(promises);

            // Create a Set for each status
            let scheduledChecklistIds = new Set(scheduledChecklists.value.map(c => c.checklistId));
            let activeChecklistIds = new Set(activeChecklists.value.map(c => c.checklistId));
            let completedChecklistIds = new Set(completedChecklists.value.map(c => c.checklistId));
            let archivedChecklistIds = new Set(archivedChecklists.value.map(c => c.checklistId));

            let finalResults = [];

            for (const result of results) {
                for (const doc of result.docs) {

                    const checklist = {
                        checklistId: doc.id,
                        ...doc.data(),
                    };

                    // Check if the user has access to the checklist
                    if (!userHasAccess(checklist)) {
                        console.error("Access denied to checklist: ", doc.id);
                        continue;
                    }

                    // Check the appropriate Set based on the status
                    if (status === 'scheduled' && !scheduledChecklistIds.has(checklist.checklistId)) {
                        finalResults.push(checklist);
                        scheduledChecklistIds.add(checklist.checklistId);
                    } else if (status === 'active' && !activeChecklistIds.has(checklist.checklistId)) {
                        finalResults.push(checklist);
                        activeChecklistIds.add(checklist.checklistId);
                    } else if (status === 'complete' && !completedChecklistIds.has(checklist.checklistId)) {
                        finalResults.push(checklist);
                        completedChecklistIds.add(checklist.checklistId);
                    } else if (isArchived && !archivedChecklistIds.has(checklist.checklistId)) {
                        finalResults.push(checklist);
                        archivedChecklistIds.add(checklist.checklistId);
                    }
                }
            }

            // Add the fetched checklists to the corresponding array based on status and isArchived
            if (status === 'scheduled' && !isArchived) {
                scheduledChecklists.value.push(...finalResults);
                scheduledChecklists.value = sortChecklists(scheduledChecklists.value, 'scheduled');
            } else if (status === 'active' && !isArchived) {
                activeChecklists.value.push(...finalResults);
                activeChecklists.value = sortChecklists(activeChecklists.value, 'active');
            } else if (status === 'complete' && !isArchived) {
                completedChecklists.value.push(...finalResults);
                completedChecklists.value = sortChecklists(completedChecklists.value, 'complete');
            } else if (isArchived) {
                archivedChecklists.value.push(...finalResults);
                archivedChecklists.value = sortChecklists(archivedChecklists.value, 'archived');
            }

            fetchingData.value = false;
        } catch (e) {
            console.error("Error fetching checklists: ", e);
            fetchingData.value = false;
            fetchChecklistError.value = `Please refresh the page and try again. If the problem persists, contact support.`;
        }
    };

    return { refreshChecklists, resetChecklistsStore, fetchChecklists, fetchingData, fetchChecklistError, scheduledChecklists, activeChecklists, completedChecklists, archivedChecklists, selectedOption };
});
